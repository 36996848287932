/**
* Get a single element for the specified selector.
* Alias for document.querySelector.
*
* @param {string} s
* @returns HTMLElement
*/
export const $ = (s) => document.querySelector(s);

/**
* Get all elements for the specified selector.
* Alias for document.querySelectorAll.
*
* @param {string} s
* @returns NodeList
*/
export const $$ = (s) => document.querySelectorAll(s);

/**
* Get the element for the specified id selector.
* Alias for document.getElementById.
*
* @param {string} id
* @returns HTMLElement
*/
export const $id = (id) => document.getElementById(id);

/**
* Get an element's window offset bounds
*
* @param {HTMLElement} el
* @returns Object containing postions and dimensions
*/
export const offset = (el) => {
  const { top, right, left, width, height } = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return {
    width,
    height,
    right: (window.innerWidth + scrollLeft) - right,
    top: top + scrollTop,
    left: left + scrollLeft,
    bottom: top + scrollTop + height,
  };
};

/**
* Delegate an event. Uses event bubbling to trigger only for the specified elements.
* Usefuel if there are a lot of elements that need the same event without adding
* a lot of individual event listeners.
*
* @param {HTMLElement} element The element to attach the event listener to
* @param {string} eventName The event type to listen to
* @param {string} selector A selector to get the elements that should listen to the event
* @param {Function} callback The function to execute
* @param {Object=} options Optional options for the event listener
* @returns {void}
*/
export const delegate = function (element, eventName, selector, callback, options = {}) {
  if (element === null) {
    console.warn('Element not found', { el: element });
    return;
  }

  // eslint-disable-next-line
  element.addEventListener(eventName, (event) => {
    const possibleTargets = element.querySelectorAll(selector);
    const { target } = event;

    for (let i = 0, l = possibleTargets.length; i < l; i += 1) {
      let el = target;
      const p = possibleTargets[i];

      while (el && el !== element) {
        if (el === p) {
          return callback.call(p, event, i);
        }

        el = el.parentNode;
      }
    }
  }, options);
};

/**
* Add an event listener to and Array/NodeList of DOM Elements
*
* @param {NodeList} elements The elements to bind the listener to.
* @param {string} eventName The event type to listener to
* @param {Function} callback The function to execute
* @param {Object=} options Optional options for the event listener
* @returns {Function} Function to remove event listener
*/
export const addEventListenerAll = (elements, eventName, callback, options = {}) => {
  for (let i = 0; i < elements.length; i += 1) {
    const el = elements[i];
    el.addEventListener(eventName, callback, options);
  }

  return () => {
    for (let i = 0; i < elements.length; i += 1) {
      const el = elements[i];
      el.removeEventListener(eventName, callback);
    }
  };
};

/**
* Load content from an url
*
* @param {string} link The url to load data from.
* @param {string=} type The data return type. Will return text if not set.
* @returns Promise
*/
export const loadFromUrl = (link, type) => (
  new Promise((resolve, reject) => {
    fetch(link, { credentials: 'same-origin' })
      .then((response) => {
        if (type === 'json') {
          return response.json();
        }

        return response.text();
      })
      .then((res) => resolve(res))
      .catch((reason) => reject(reason));
  })
);

/**
* Throttle a function call to only execute every N milliseconds
*
* @param {Function} callback Function to execute
* @param {number} wait Time to wait between callback calls
* @param {Object=} options
* @returns {Function}
*/
export const throttle = function (callback, wait, options = {}) {
  let context;
  let args;
  let result;
  let timeout = null;
  let previous = 0;
  const later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = callback.apply(context, args);
    if (!timeout) context = args = null; // eslint-disable-line
  };

  return function () {
    const now = Date.now();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments; // eslint-disable-line
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = callback.apply(context, args);
      if (!timeout) context = args = null; // eslint-disable-line
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }

    return result;
  };
};

/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds
 *
 * @param {Function} callback Function to execute
 * @param {number} time Time to wait
 * @returns {Function}
 */
export const debounce = function (callback, time) {
  let interval;
  return function (...args) {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback.apply(this, ...args);
    }, time);
  };
};

/**
* Get the closest parent element for a css class
*
* @param {HTMLElement} el
* @param {string} cssclass
* @returns {HTMLElement}
*/
export const closestParent = (el, cssclass) => {
  let parent = el.parentElement;
  while (parent) {
    // don't go higher than the body element
    if (parent.nodeName === 'BODY') {
      break;
    }

    // element found
    if (parent.classList.contains(cssclass) === true) {
      break;
    }

    parent = parent.parentElement;
  }

  return parent;
};

/**
* Create a query string from an object
*
* @param {Object} params The request parameters
* @param {boolean} q Should an ? be prepended to the query string
* @returns {string}
*/
export const queryString = (params, q = true) => {
  const query = q ? '?' : '';
  return query + Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
};

/**
* Find the index from a DOM Element
*
* @param {Object} params
* @returns {number}
*/
export const getElementIndex = (node) => {
  const prev = node.previousElementSibling;
  let index = 0;

  if (prev !== null) {
    const parent = node.parentElement;

    if (parent !== null) {
      const { children } = parent;
      for (let i = 0; i < children.length; i++) {
        const el = children[i];
        if (el === node) {
          index = i;
          break;
        }
      }
    }
  }

  return index;
};

/**
 * Create a UUID
 *
 * @returns {string} Generated UUID
 */
export const generateUUID = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0; // eslint-disable-line no-bitwise
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16); // eslint-disable-line
  });
};

/**
 * Create a random ID
 *
 * @returns {string} Generated ID
 */
export const randomStringID = () => (
  Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10)
);

/**
 * @param {string} str
 * @returns {string}
 */
export const fixedEncodeURIComponent = (str) => (
  encodeURIComponent(str).replace(/[!'()*]/g, (c) => (
    `%${c.charCodeAt(0).toString(16)}`
  ))
);

/**
 * match text case insensitive
 * @param {string} str
 * @returns {string}
 */
const pregQuote = (str) => (
  `${str}`.replace(/([\\.+*?[^]\$\(\)\{\}=!<>|:])/g, '\\$1')
);

const accentMap = {
  ä: 'a',
  ö: 'o',
  ü: 'u',
  á: 'a',
  é: 'e',
  è: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u',
  Ç: 'c',
};

const accentFold = (s) => {
  if (!s) { return ''; }
  let ret = '';
  for (let i = 0; i < s.length; i++) {
    ret += accentMap[s.charAt(i)] || s.charAt(i);
  }
  return ret;
};

/**
 * Mark text
 * https://stackoverflow.com/a/280805
 *
 * @param {string} data The text String to mark text in
 * @param {string} query The text to mark
 * @returns {string} HTML string containing marked text
 */
export const highlight = (data, query) => {
  const quoted = pregQuote(query);
  const exactMatch = data.replace(new RegExp(`(${quoted})`, 'gi'), '<mark>$1</mark>');

  // return if we have an exact match
  if (exactMatch.indexOf('<mark>') !== -1) {
    return exactMatch;
  }

  // accent fold query and text string
  const foldedText = accentFold(data);
  const foldedQuery = accentFold(quoted);

  // mark query in folded text
  const foldedMatch = foldedText.replace(new RegExp(`(${foldedQuery})`, 'gi'), '{$1}');

  // get position of markers
  const firstIndex = foldedMatch.indexOf('{');
  const lastIndex = foldedMatch.indexOf('}');

  // get the actual string to mark from the marker indexes
  const textToMark = data.substring(firstIndex, lastIndex - 1);
  return data.replace(new RegExp(`(${textToMark})`, 'gi'), '<mark>$1</mark>');
};

/**
 * Collapse without animation
 *
 * @param {Event} e
 * @param {HTMLElement} el The element that would control the collapse
 * @returns {void}
 */
export const expandCollapse = (e, el) => {
  if (e instanceof Event) {
    e.preventDefault();
  }

  const control = el || this;

  if (control.classList.contains('collapsed')) {
    // if another accorion element is open, close it
    if (control.dataset.parent) {
      const parent = document.querySelector(control.dataset.parent);
      if (parent !== null) {
        const openSibling = parent.querySelector('.accordion-toggle:not(.collapsed)');
        if (openSibling !== null) {
          openSibling.classList.add('collapsed');
          openSibling.setAttribute('aria-expanded', false);
          const target = document.querySelector(openSibling.getAttribute('href'));
          target.setAttribute('aria-expanded', false);
          target.classList.remove('show');
        }
      }
    }

    control.classList.remove('collapsed');
    control.setAttribute('aria-expanded', true);
    const target = document.querySelector(control.getAttribute('href'));
    target.setAttribute('aria-expanded', true);
    target.classList.add('show');
  } else {
    control.classList.add('collapsed');
    control.setAttribute('aria-expanded', false);
    const target = document.querySelector(control.getAttribute('href'));
    target.setAttribute('aria-expanded', false);
    target.classList.remove('show');
  }
};

/**
 * Throttle a function call with requestAnimationFrame
 *
 * @param {function} callback The function to throttle
 * @returns {function} The throttled function
 */
export const rafThrottle = (callback, context = null) => {
  const self = context || this;
  let requestId;

  const later = (ctx, args) => () => {
    requestId = null;
    callback.apply(ctx, args);
  };

  const throttled = (...args) => {
    if ((requestId === null) || (requestId === undefined)) {
      requestId = requestAnimationFrame(later(self, args));
    }
  };

  throttled.cancel = () => {
    cancelAnimationFrame(requestId);
  };

  return throttled;
};

/**
 * Get the last part of an url path
 *
 * @param {string} url The url
 * @returns {string} The last part
 */
export const getLastUrlPart = (url) => {
  const noslash = url.replace(/\/$/, '');
  const lastIndex = noslash.lastIndexOf('/');
  return noslash.substr(lastIndex, noslash.length);
};

/**
 * get href and remove leading slash (/) and trailing extensions (.html, etc.)
 *
 * @param {string} href The unpure href
 * @returns {string} The pure href
 */
export const getPureHref = (href) => href.replace(/^\.?\//, '').replace(/\..*/, '');

/**
 * Wrap a html element with another
 *
 * @param {HTMLElement} el The element to wrap
 * @param {HTMLElement} wrapper The wrapper element
 * @returns {void}
 */
export const wrap = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
};

/**
 * Keep a number between a min and max value
 *
 * @param {number} number The initial number
 * @param {number} min Tha minimum value
 * @param {number} max Tha maximum value
 * @returns {number}
 */
export const range = (number, min, max) => (
  Math.min(Math.max(parseInt(number, 10), parseInt(min, 10)), parseInt(max, 10))
);

/**
 * Check if url contains debug query string
 *
 * @returns {boolean}
 */
export const isDebug = (localOverrride = false) => {
  if (localOverrride === true && window.location.hostname === 'localhost') {
    return true;
  }

  if (!URLSearchParams) {
    console.info('URLSearchParams function not available');
    return false;
  }

  const params = new URLSearchParams(window.location.search);
  return params.has('debug');
};

export const isInViewport = (el) => {
  const bounding = el.getBoundingClientRect();
  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
