import smoothscroll from 'smoothscroll-polyfill';
import { $, $$, offset } from './helper/utils';
import outline from './helper/outline';
import { MOBILMEDIA, PHONEMEDIA } from './helper/constants';
import { ScrollTrigger } from '../libs/gsap/ScrollTrigger';
import { MotionPathPlugin } from '../libs/gsap/MotionPathPlugin';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

const sliderMap = new Map();
const sliderDragging = new Map();

outline();
smoothscroll.polyfill();

export default function () {
  let mobileNavLoaded = false;

  const loadMobileNav = () => {
    if ($('.mobile-nav') !== null) {
      import('./components/offcanvas')
        .then((offcanvas) => {
          mobileNavLoaded = true;
          const mobileNav = $('.mobile-nav');
          const showMenuBtn = $('.show-menu');
          const mobileMenu = offcanvas.default(mobileNav, showMenuBtn, 'top');

          showMenuBtn.addEventListener('click', mobileMenu.toggle, { passive: true });
        }).catch((err) => {
          console.warn(`Chunk offcanvas.js loading failed: ${err}`);
        });
    } else {
      mobileNavLoaded = true;
    }
  };

  MOBILMEDIA.addListener((e) => {
    if (e.matches) {
      if (mobileNavLoaded === false) loadMobileNav();
    }
  });

  if (MOBILMEDIA.matches) {
    loadMobileNav();
  }

  if ($('.scroll-down') !== null) {
    $('.scroll-down').addEventListener('click', () => {
      const section = $('.scroll-down').closest('.row').nextElementSibling;
      if (section !== null) {
        window.scrollTo({
          top: offset(section).top,
          behavior: 'smooth',
        });
      }
    });
  }

  if ($$('.scroll-to-id') !== null) {
    $$('.scroll-to-id').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const target = el.getAttribute('href');
        const scrollSection = $(target);
        if (scrollSection !== null) {
          window.scrollTo({
            top: offset(scrollSection).top,
            behavior: 'smooth',
          });
        }
      });
    });
  }

  // if ($('.show-menu') !== null) {
  //   import('./components/scrollTweens')
  //     .catch((err) => {
  //       console.warn('Chunk scrollTweens.js loading failed', err);
  //     });
  // }
  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.card-slider') !== null && PHONEMEDIA.matches) {
    import('./components/cardSlider')
      .then((slider) => slider.default())
      .catch((err) => {
        console.warn('Chunk cardSlider.js loading failed', err);
      });
  }

  if ($('.form-group > .form-line') !== null) {
    import('./components/forms/form')
      .then((form) => form.default())
      .catch((err) => {
        console.warn('Chunk forms/form.js loading failed', err);
      });
  }

  if ($('.accordions') !== null) {
    import('./components/accordions')
      .then((accordions) => accordions.default())
      .catch((err) => {
        console.warn('Chunk accordions.js loading failed', err);
      });
  }

  if ($('.show-more-text') !== null) {
    import('./components/showMoreText')
      .then((showMoreText) => showMoreText.default())
      .catch((err) => {
        console.warn('Chunk showMoreText.js loading failed', err);
      });
  }

  if ($('.spenden-form') !== null) {
    import('./components/spendenForm')
      .then((spendenForm) => spendenForm.default())
      .catch((err) => {
        console.warn('Chunk spendenForm.js loading failed', err);
      });
  }

  if ($('.splide') !== null) {
    import('./components/splideSlider')
      .then((splideSlider) => splideSlider.default())
      .catch((err) => {
        console.warn('Chunk splideSlider.js loading failed', err);
      });
  }

  if ($('.scroll-reveal') !== null) {
    import('./components/scrollReveal')
      .then((scrollReveal) => scrollReveal.default())
      .catch((err) => {
        console.warn('Chunk scrollReveal.js loading failed', err);
      });
  }

  if ($('.svgAnimation') !== null) {
    import('./components/svgAnimations')
      .then((svgAnimations) => svgAnimations.default())
      .catch((err) => {
        console.warn('Chunk svgAnimations.js loading failed', err);
      });
  }

  if ($('.count-up') !== null) {
    import('./components/countUp')
      .then((countUp) => countUp.default())
      .catch((err) => {
        console.warn('Chunk countUp.js loading failed', err);
      });
  }

  if ($('.image-grid') !== null) {
    import('./components/imageGrid')
      .catch((err) => {
        console.warn('Chunk imageGrid.js loading failed', err);
      });
  }

  if ($('.dropdown.mobile-nav-link') !== null) {
    $('.dropdown.mobile-nav-link').addEventListener('click', () => {
      $('.dropdown.mobile-nav-link').classList.toggle('active');
    });
  }
}
